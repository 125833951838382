import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import DashboardNav from "../../../components/navbar";
import {
  RealmAppProvider,
  useRealmApp,
  OnboardingRedirector,
  LogOutButton,
  APP_ID,
} from "../../../components/realm_app";
import { defaultPhoneInputValidation, validateName } from "../../../utils";
import styles from "../../../styles.css.ts";
import "./settings.css";
import { Footer } from "../../../components/footer";
import { Button } from "react-bootstrap";

const SettingsPage = () => {
  const realmApp = useRealmApp();

  const [name, setName] = useState(realmApp.appCustomData?.username);
  const [phone, setPhone] = useState(realmApp.appCustomData?.phone);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [loading, setLoading] = useState("");
  const [circleInfo, setCircleInfo] = useState(null);
  const [leaveCircleBtn, setleaveCircleBtn] = React.useState(<>loading...</>);
  const [deleteAccountBtn, setDeleteAccountBtn] = React.useState(
    <>loading...</>
  );

  function leaveCircle() {
    const confirmed: boolean = confirm(
      "If you have not given your team a heads up, please press 'Cancel' and sync with them first. Press 'OK' if you are sure you are ready to leave."
    );
    if (confirmed) {
      realmApp.appCurrentUser?.functions.leaveCircle(
        realmApp.appCustomData?._id
      );
      navigate("/member/signup/join_circle/");
    }
  }

  async function deleteAccount() {
    const confirmed: boolean = confirm(
      "If you have not given your team a heads up, please press 'Cancel' and sync with them first. Press 'OK' if you are sure you are ready to leave."
    );
    if (confirmed) {
      await realmApp.appCurrentUser?.functions.leaveCircle(
        realmApp.appCustomData?._id
      );
      realmApp.appCurrentUser?.functions.dropUserDocumentById(
        realmApp.appCustomData?._id
      );
      realmApp.LogOut();
    }
  }

  async function handleSave() {
    setLoading("saving contact info...");

    var validated = true;

    if (!isPhoneValid) {
      alert(
        "Invalid phone number. Please select a country code using the dropdown menu and fill in your phone number."
      );
      setPhone(realmApp.appCustomData?.phone);
      validated = false;
    }
    try {
      validateName(name);
    } catch (err) {
      alert(err);
      setName(realmApp.appCustomData?.username);
      validated = false;
    }

    const phoneDiff = phone != realmApp.app.currentUser?.customData?.phone;
    const nameDiff = name != realmApp.app.currentUser?.customData?.username;

    if (validated && (phoneDiff || nameDiff)) {
      console.log("Saving contact info...");
      const data = { username: name, phone: phone };
      const result = await realmApp.setMongoUserAttribute(data, true);

      if ("modifiedCount" in result && result["modifiedCount"] == 1) {
        if (
          (phoneDiff && realmApp.app.currentUser.customData.phone == phone) ||
          (nameDiff && realmApp.app.currentUser.customData.username == name)
        ) {
          console.log("user attribute successfully set");
          // window.location.reload();
          setLoading("saved!");
        } else {
          alert("something went wrong. Please refresh page and try again.");
          setLoading("");
        }
      } else {
        alert(
          "could not save profile info. Please refresh page and try again."
        );
        setLoading("");
      }
    }
  }

  async function fetchCircleInfo() {
    const circle = await realmApp.fetchCircle();
    if (circle) {
      console.log(circle);
      setCircleInfo(circle);
      setleaveCircleBtn(
        <button onClick={() => leaveCircle()}>
          Leave {circle.circle_name}
        </button>
      );
      setDeleteAccountBtn(
        <button onClick={() => deleteAccount()}>Delete my account</button>
      );
    }
  }

  useEffect(() => {
    fetchCircleInfo();
  }, []);

  return (
    <>
      <div className="page settings">
        <DashboardNav />
        <br />
        <br />
        <div className="page-content" style={{ textAlign: "left" }}>
          <div className="settings-heading">
            <h2 className="settings-title">Settings</h2>
            <LogOutButton />
          </div>
          <section className="light-card p-4">
            <div className="d-inline-flex flex-column align-top">
              <div className="pb-3">
                <b>NAME</b>
                <br />
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="name-input"
                />
              </div>
              <div className="pb-3">
                <b>PHONE</b>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  inputProps={{
                    required: true,
                  }}
                  isValid={defaultPhoneInputValidation}
                  onChange={(phoneDigits, country) => {
                    setPhone(`+${phoneDigits}`);
                    setIsPhoneValid(
                      defaultPhoneInputValidation(phoneDigits, country)
                    );
                  }}
                />
              </div>
            </div>
            <div className="d-inline-block pb-3 email-margin-lft">
              <b>EMAIL</b>
              <p className="pt-1">{realmApp.appCustomData?.name}</p>
            </div>
            <div>
              <Button
                className="deepen-blue-button"
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </div>
          </section>
          {/*
          <br />
          <br />
          <br />
          <h3>Account</h3>
          {leaveCircleBtn}
          <br />
          <br />
          {deleteAccountBtn}
          <br />
          <br />
          <br /> */}
          {loading}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <SettingsPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
